<template>
  <div id="paypal-button">
    <paypal-checkout
      :env="paypalEnv"
      :amount="toPay"
      currency="USD"
      @payment-cancelled="onCancelled"
      @payment-completed="onCompleted"
      v-bind:client="paypal">
    </paypal-checkout>
  </div>
</template>
<script>
import PaypalCheckout from 'vue-paypal-checkout';

export default {
  name: 'FluffyPayPal',
  props: {
    subscriptionType: String,
  },
  computed: {
    paypalEnv() {
      return process.env.NODE_ENV === 'production' ? 'production' : 'sandbox';
    },
    buttonStyle() {
      return {
        label: 'Shut up and take my money!',
        size: 'large',
        shape: 'pill',
        color: 'gold',
      };
    },
    toPay() {
      if (this.subscriptionType) {
        if (this.subscriptionType === '1') return '0.99';
        return '4.99';
      }
      return '0.99';
    },
  },
  components: {
    PaypalCheckout,
  },
  data() {
    return {
      paypal: {
        sandbox: 'AWH9RGDQIztcByB7OpLZHRUhXSkmkNzjRnew84FDNi8XAxfOyaoGKfeRZ44EcpecBJqDOzJXu_-_XoV-',
        production: 'AT8yjAm4W0tEKha2_9rt99zUA2AHs2rbZUcG4Z2ipcgrHpsLt_pN_xcoo5nvWWgu2u1y46wHnKk9g9Sn',
      },
    };
  },
  methods: {
    onCancelled() {
      this.$store.dispatch('setAlert', {
        show: true,
        title: 'Having double thoughts?',
        content: 'Click here to check out a video on how it works and what you get',
      });
    },
    onCompleted() {
      this.$store.dispatch('setAlert', {
        show: true,
        title: 'Payment completed',
        content: 'Thank you for your support!',
      }).then(() => {
        // set state for current session:
        this.$store.dispatch('setUserSubscription', this.subscriptionType);
        // write data on Firebase for this User:
        this.$store.dispatch('updateUserSubscription', this.subscriptionType).then(() => {
          this.$router.push({ path: '/' });
        });
      });
    },
  },
};
</script>
