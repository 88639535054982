<template>
  <section class="go-pro-dialog">
    <md-card v-if="!$store.getters.getIsUserLoggedIn">
      <md-card-header>
        <div class="md-title">
          Please signup for an account first, then subscribe to our services:
        </div>
      </md-card-header>
      <md-card-content>
        <div class="auth-wrapper">
          <AuthView/>
        </div>
      </md-card-content>
    </md-card>
    <md-card class="blurred-content">
      <md-card-header>
        <div class="md-title">Confirm your support below:</div>
      </md-card-header>
      <md-card-content>
        <div v-if="subscriptionType === '2'" :class="subscriptionType === '2' ?
          'radio-wrapper --selected --big' : 'radio-wrapper --big'">
          <md-radio v-model="subscriptionType" value="2" class="md-primary">
            <div class="radio-container">
              <ul>
                <li>No ads</li>
                <li>Unlimited runs*</li>
                <li>Skip the line with a faster response</li>
                <li>Access to beta features realted to 3D modelling</li>
              </ul>
              <h3 class="price-span">I'm in 🦾 (<strong>$4.99/ month</strong>)</h3>
            </div>
          </md-radio>
        </div>
        <div v-else-if="subscriptionType === '1'" :class="subscriptionType === '1' ?
            'radio-wrapper --selected' : 'radio-wrapper'">
          <md-radio v-model="subscriptionType" value="1" class="md-primary">
            <div class="radio-container">
              <ul>
                <li>No ads</li>
                <li>3 runs</li>
              </ul>
              <h3 class="price-span">
                Just a 🍺 this time <strong>($0.99)</strong>
              </h3>
            </div>
          </md-radio>
        </div>
        <md-checkbox v-model="accepted">I have read and hereby accept Fac3d's Terms & Conditions
        </md-checkbox>
      </md-card-content>
      <br/>
      <br/>
      <br/>
      <md-card-actions md-alignment="space-between">
        <md-button class="md-raised md-accent"
                   @click.prevent="$router.push({ path: '/' })">
          Cancel
        </md-button>
        <pay-pal-component
          :subscription-type="subscriptionType"
          v-if="$store.getters.getIsUserLoggedIn && accepted"
        />
      </md-card-actions>
    </md-card>
    <br/>
    <br/>
    <br/>
  </section>
</template>
<script>
// import GooglePay from '../components/GooglePay.vue';
import PayPalComponent from '../components/PayPalComponent.vue';
// import Register from '../components/auth/Register.vue';
import AuthView from './Auth.vue';

export default {
  name: 'SupportUsBufferView',
  components: {
    PayPalComponent,
    AuthView,
  },
  data() {
    return {
      subscriptionType: '',
      accepted: false,
    };
  },
  mounted() {
    if (this.$route.params.type) {
      this.subscriptionType = this.$route.params.type;
      // setTimeout(() => {
      //   this.$store.dispatch('updateUserSubscription', this.subscriptionType);
      // }, 250);
    }
  },
};
</script>
<style lang="scss" scoped>
.auth-wrapper {
  height: auto;
  padding: 0;
  border-radius: 20px;
}
.go-pro-dialog {
  .md-card.md-theme-default {
    padding: 1.5rem;
    border-radius: 34px;
    box-shadow: none;
  }
  .md-card-actions {
    left: 0;
  }
}
</style>
